$pink: #EC3FB5;
$black: #000000;
$white: #fff;




$main: $pink;
$offset: #fff;
$text: $black;
$hr: #aaa;



$border: $pink;
$orange: $pink;
$darktext: $text;