input {

    &[type=text],
    &[type=email] {

        padding: 0.35em;
        border-radius: 0.5em;
        border: 1px solid $hr;
        outline: none;
        &:focus-visible {
            border-radius: 0.5em;
            border: 1px solid $main;
            box-shadow: 0 0 0 1px $main;
        }
    }
}