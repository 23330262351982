.nowrap {
  white-space: nowrap;
}
.hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  height: 0;
  width: 0;
  overflow: hidden;
}
.mb-1 {
  margin-bottom: 0.3em !important;
}
.hint {
  position: relative;
  &:after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: inherit;
    animation: hint 2s cubic-bezier(0.18, 0.49, 0.51, 1.01) infinite 2.5s;
    @keyframes hint {
      0% {
        box-shadow: 0 0 0 0em $pink;
        opacity: 1;
      }
      40% {
        box-shadow: 0 0 0 0.6rem $pink;
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.center {
  text-align: center;
}
