$orvany: 'Oravny';

@font-face {
    font-family: $orvany;
    src: url('./../../fonts/FtOrvany\ 0.81-VF.ttf');
}

body,
input {
    font-variation-settings: "wght"400, "wdth"1000;
    font-family: $orvany, arial, sans-serif;
}

body {
    line-height: 1.2;
}

ul,
p {
    margin-bottom: 0.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    i {
        font-size:0.85em;
        margin-inline-end: 0.1em;
    }
}

h1,
h2 {
    // font-variation-settings: "wght" 400, "wdth"100;
    line-height: 1.1;
    margin-bottom: 0.3em;
}

h3,
h4,
h5,
h6 {
    margin-bottom: 0.3em;
    font-variation-settings: "wght"700, "wdth"500;
    line-height: 1.2;
    // color: $main;
}



h2 {
    // margin-bottom: 0em;
}

.page-title {
    text-align: center;
    font-variation-settings: "wght"500, "wdth"100;
    margin-bottom: 0.5em;
    font-size: 2.2em;
    color: $main;
    .subtitle {
        // color: $text;
        display: block;
        font-size: 0.37em;
        font-variation-settings: "wght"700, "wdth"500;
    }
}

h3 {
    font-variation-settings: "wght"500, "wdth"500;
}