.bouncing-letters {
  $initial_wait: -2350ms;
  $duration: 3700ms;
  $delay_between_each: 30ms;
  span.letter {
    display: inline-block;
    transform-origin: center;
    @keyframes MoveUpDown2 {
      0% {
        transform: translateY(0em) rotate(0deg) scale(1);
        font-variation-settings: "wght"400, "wdth"100;
        letter-spacing: 0.01em;
      }
      80% {
        transform: translateY(0.00em) rotate(0deg) scale(1);
        font-variation-settings: "wght"400, "wdth"100;
        letter-spacing: 0.01em;
      }
      85% {
        transform: translateY(0.12em) scale(0.95);
      }
      90% {
        transform: translateY(-0.18em) scale(1.03);
        font-variation-settings: "wght"700, "wdth"100;
        // letter-spacing: 0.045em;
        // letter-spacing: -0.01em;
        letter-spacing: -0.00em;
      }
      95% {
        transform: translateY(0em) scale(0.95);
      }
      100% {
        transform: translateY(0em) scale(1);
        font-variation-settings: "wght"400, "wdth"100;
        letter-spacing: 0.01em;
      }
    }
    animation: MoveUpDown2 $duration ease-in-out infinite;
    @for $i from 0 through 40 {
      &:nth-child(#{$i}) {
        animation-delay: $initial_wait + $delay_between_each * $i;
      }
    }
  }
}
